import React from "react"

import useImages from "../hooks/useImages"
import useViewportSize, { SIZES } from "../hooks/useViewportSize"

import { pin, pinStart } from "../icons"

import Layout from "../components/layout"
import Map from "../components/map"
import Section from "../components/section"
import Separator from "../components/separator"

export default function Boda({ data }) {
  const { hero } = useImages()
  const viewportSize = useViewportSize()
  const heroContent = <></>
  const POIs = [
    {
      title: "Atrio",
      icon: pin,
      lat: 43.557411564620836,
      lng: -5.9244931309979725,
      link: "https://goo.gl/maps/ZGGsXhhrG3WgwHGz8",
      img:
        "/img/atrio.jpg",
    },
    {
      title: "El Ventorrillo",
      icon: pin,
      lat: 43.55928828162067,
      lng: -5.9654045041474815,
      link: "https://goo.gl/maps/3Kx7xsMFKsJn74h2A",
      img:
        "/img/ventorrillo.png",
    },
    {
      title: "Rotonda de la Vegona",
      icon: pin,
      lat: 43.570792396679806,
      lng: -5.961113113020828,
      link: "https://goo.gl/maps/oxvtDU5oio3kdZzs5",
      img:
        "/img/salinas.jpg",
    },
    {
      title: "Ayuntamiento de Piedras",
      icon: pin,
      lat: 43.5595097560865,
      lng: -5.9774858368521295,
      link: "https://goo.gl/maps/CjNSrdnWXQiipsyt9",
      img:
        "/img/ayuntamiento-piedras.jpg",
    },
    {
      title: "Castillo de Moutas",
      icon: pinStart,
      lat: 43.489871764077236,
      lng: -6.113591194152833,
      link: "https://goo.gl/maps/zWV9JJbhEwRC8gim6",
      img:
        "https://lh5.googleusercontent.com/p/AF1QipMumX_Ik_pS6zHmDkHzdRvknmCbWyPiMFIw0aNa=w426-h240-k-no",
    }
  ]

  return (
    <Layout
      heroImg={hero.childImageSharp}
      heroContent={heroContent}
      heroHeight={viewportSize === SIZES.XS ? "100px" : "250px"}
    >
      <Section className={"text-block"}>
        <h2>Paradas del autocar</h2>
        <Separator />
        <p>El autobús es de la empresa Cabo peñas y tendrá un cartel con nuestro nombre. Sed puntuales que el autobús no espera.</p>
        
        <h3>Ida</h3>
        
        <p>Os ponemos a continuación las paradas de autobús y el horario de <b>salida</b> para la ida.</p>

        
          <p>10:45 ATRIO</p>

          <p>11:00 EL VENTORRILLO</p>

          <p>11:05 Parada Bus ROTONDA DE LA VEGONA</p>

          <p>11:10 Ayto de PIEDRAS</p>

          <p>11:30 LLEGADA A PRAVIA</p>
        

        <h3>Vuelta</h3>
        <p>La vuelta la hará el autobús a las 23:30 y a las 2 de la mañana</p>
      </Section>

      <Map
        center={{
          lat: 43.51803584758218,
          lng: -5.963492496782673,
        }}
        zoom={11}
        POIs={POIs}
      />
    </Layout>
  )
}
